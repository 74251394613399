.viewRoot {
    flex-direction: column;
}

.viewLoading {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.header-main {
    font-weight: bold;
    font-size: 20px;
    color: #ffffff;
    background-color: #10425d;
    padding: 20px;
    box-shadow: 0px 4px 4px #808888;
}

.avatar {
    width: 120px;
    height: 120px;
    border-radius: 60px;
    align-self: center;
    margin-top: 50px;
    object-fit: cover;
}

.textLabel {
    font-weight: bold;
    color: #ffffff;
    font-style: italic;
    font-size: 18px;
    margin-top: 50px;
    margin-left: -400px;
}

.textInput {
    width: 500px;
    align-self: center;
    border: none;
    background-color: #ffffff;
    border-radius: 3px;
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    color: white;
    margin-top: 10px;
}

input::placeholder {
    color: rgb(199, 199, 199);
}

.btnUpdate {
    background-color: #10425d;
    border: none;
    color: #ffffff;
    padding: 10px;
    width: 200px;
    text-align: center;
    font-size: 20px;
    align-self: center;
    border-radius: 8px;
    font-weight: bold;
    margin-top: 100px;
    cursor: pointer;
}

.viewWrapInputFile {
    position: absolute;
    align-self: center;
    top: 215px;
    background-color: rgba(1, 1, 1, 0.5);
    width: 40px;
    height: 40px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center
}

.viewInputFile {
    position: absolute;
    width: 40px;
    height: 40px;
    left: 0px;
    outline: 0;
    z-index: -1;
    opacity: 0;
}

.imgInputFile {
    border: none;
    width: 40px;
    height: 40px;
    padding: 5px;
}
