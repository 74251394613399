.root {
    text-align: center;
    flex-direction: column;
    display: flex;
}

.header-main {
    font-weight: bold;
    font-size: 20px;
    color: #ffffff;
    background-color: #10425d;
    padding: 20px;
    box-shadow: 0 4px 4px #808888;
    flex-direction: row;
}

.body {
    display: flex;
    flex-direction: row;
    padding-top: 5px;
}

.viewLoading {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.icLogout {
    width: 25px;
    height: 25px;
    position: absolute;
    right: 0;
    margin-right: 30px;
    cursor: pointer;
}

.icProfile {
    width: 25px;
    height: 25px;
    position: absolute;
    right: 0;
    margin-right: 80px;
    cursor: pointer;
    padding-right: 10%;
}

/* Dialog confirm */

.viewCoverScreen {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.viewWrapTextDialogConfirmLogout {
    width: 300px;
    height: 50px;
    background-color: #10425d;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center
}

.titleDialogConfirmLogout {
    color: white;
    font-weight: bold;
    font-size: 18px;
}

.viewWrapButtonDialogConfirmLogout {
    flex-direction: row;
    height: 100px;
    width: 300px;
    min-width: 300px;
    background-color: white;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    align-items: center;
    justify-content: center;
    display: flex;
}

.btnYes {
    width: 100px;
    height: 40px;
    font-weight: bold;
    margin-right: 20px;
    background-color: #000;
    border: 0px;
    color: white;
    border-radius: 4px;
    cursor: pointer;
}

.btnNo {
    width: 100px;
    height: 40px;
    font-weight: bold;
    margin-left: 20px;
    padding: 5px;
    background-color: #dd4b39;
    border: 0px;
    color: white;
    border-radius: 4px;
    cursor: pointer;
}

/* List user */

.viewListUser {
    overflow-y: scroll;
    max-height: 90vh;
    padding-top: 10px;
    padding-bottom: 10px;
}

.viewListUser::-webkit-scrollbar-track {
    padding: 2px 0;
    background-color: #e8e8e8;
}

.viewListUser::-webkit-scrollbar {
    width: 6px;
}

.viewListUser::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #ffffff;
}

.viewWrapItem {
    display: flex;
    flex-direction: row;
    background-color: #ffffff;
    width: 250px;
    padding: 10px;
    align-items: center;
    justify-content: center;
    border: solid 1px;
}

.viewWrapItemFocused {
    border: none;
    display: flex;
    flex-direction: row;
    background-color: #e8e8e8;
    width: 250px;
    padding: 10px;
    align-items: center;
    justify-content: center;
}

.viewAvatarItem {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    object-fit: cover;
}

.viewWrapContentItem {
    flex-direction: column;
    display: flex;
    flex: 1;
    margin-left: 15px;
    color: #203152;
    word-wrap: break-word;
}

.textItem {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 180px;
    text-align: left;
    font-size: 14px;
    font-weight: bold;

}
.textItem1 {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 180px;
    text-align: left;
    padding-top: 5px;
    font-size: 10px;
}

.viewBoard {
    display: flex;
    flex: 1;
    max-height: 90vh;
}

