.viewRoot {
    text-align: center;
    flex-direction: column;
}

.viewLoading {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.header-main {
    font-weight: bold;
    font-size: 20px;
    color: #ffffff;
    background-color: #10425d;
    padding: 20px;
    box-shadow: 0px 4px 4px #808888;
}

/* Button login */

.btnLogin {
    background-color: #dd4b39;
    border: none;
    color: white;
    padding: 10px;
    width: 400px;
    text-align: center;
    font-size: 20px;
    align-self: center;
    border-radius: 8px;
    font-weight: bold;
    margin-top: 200px;
    outline: 0;
    cursor: pointer;
}
